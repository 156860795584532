/*------------------------------------
    Hero
------------------------------------*/

.section-hero {
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

// Hero 1
.section-hero-1 {
    .el-heading {
        .el-icon {
            margin-bottom: 20px;
        }
    }
}


.el-heading {
    h1 {
        .mycity {
            font-size: 20px;
            color:rgb(103, 102, 100);
        }
    }
}

@media (max-width: 768px) {

    .el-heading {
        max-width: 75%;
    }

    .section-hero-2 {
        background-position: center left;
        .el-heading {
            float: inherit !important;
        }
        .display-center {
            z-index: 3;
        }
        &:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            opacity: 0.45;
            position: absolute;
            background-color: $base-0;
        }
    }
    .section-hero-5 {
        .el-heading {
            h1 {
                font-size: 24px !important;
            }
        }
    }
}


@media (max-width: 500px) and (orientation: portrait) {  
    .section-hero-1 {
        background-position: bottom left 60%;
        background-size: auto 80%;
    }
}