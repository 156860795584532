/*------------------------------------
    Reset Styels
------------------------------------*/

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root.skin-1 {
    --base-0: #fff;
    --base-1: #000;
    --primary: #2ab6bb;
    --primary-1: #177275;
    --primary-2: #181c1b;
}

:root.skin-2 {
    --base-0: #f6afba;
    --base-1: #151515;
    --primary: #de5881;
    --primary-1: rgb(252, 90, 171);
}

:root.skin-3 {
    --base-0: #050607;
    --base-1: #f1f1f1;
    --primary: #0b7554;
    --primary-1: #339266;
}

:root.skin-4 {
    --base-0: #0e262b;
    --base-1: #f1f1f1;
    --primary: #7b1f29;
    --primary-1: darkred;
}

:root.skin-5 {
    --base-0: #373a36;
    --base-1: #ffffff;
    --primary: tomato;
    --primary-1: tomato;
}

:root.skin-5 {
    --base-0: #1e464a;
    --base-1: #fff;
    --primary: #00b7b8;
    --primary-1: #339266;
}

:root.skin-6 {
    --base-0: #fff;
    --base-1: #000;
    --primary: #33b5e5;
    --primary-1: rgb(158, 221, 241);
}

:root.skin-7 {
    --base-0: #212121;
    --base-1: #fff;
    --primary: #0b7554;
    --primary-1: #0b7554;
}

:root.skin-8 {
    --base-0: #272933;
    --base-1: #fff;
    --primary: #00b7b8;
    --primary-1: #00b7b8;
}

:root.skin-9 {
    --base-0: #000;
    --base-1: #fff;
    --primary: #00bcd4;
    --primary-1: #00bcd4;
}

:root.skin-10 {
    --base-0: #0a0a0a;
    --base-1: #ffffff;
    --primary: #1d32f2;
    --primary-1: #1d32f2;
}

:root.skin-11 {
    --base-0: #121212;
    --base-1: #e6e2dd;
    --primary: #845ec2;
    --primary-1: #845ec2;
}

:root.skin-12 {
    --base-0: #0e262b;
    --base-1: #ffffff;
    --primary: #7b1f29;
    --primary-1: darkred;
}

html {
    overflow: hidden;
    overflow-y: auto;
}

body {
    color: $base-1;
    margin: 0;
    background-color: $base-0;
    -ms-overflow-style: none;
}
body::-webkit-scrollbar {
    display: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden] {
    display: none;
}
html,
button,
input,
select,
textarea {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
}
a {
    color: inherit;
    text-decoration: none;
}
a:visited {
    color: inherit;
}
a:focus {
    outline: inherit;
}
a:active,
a:hover {
    color: inherit;
    outline: 0;
}
a:hover {
    text-decoration: underline;
}
address {
    font-style: italic;
    margin: 0 0 24px;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: bold;
}
dfn {
    font-style: italic;
}
mark {
    background: #ff0;
    color: #000;
}
p {
    margin: 0 0 24px;
}
code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 14px;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
pre {
    background: #f5f5f5;
    color: #666;
    font-family: monospace;
    font-size: 14px;
    margin: 20px 0;
    overflow: auto;
    padding: 20px;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}
blockquote,
q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
blockquote {
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    margin: 24px 40px;
}
blockquote blockquote {
    margin-right: 0;
}
blockquote cite,
blockquote small {
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
}
blockquote em,
blockquote i {
    font-style: normal;
    font-weight: 300;
}
blockquote strong,
blockquote b {
    font-weight: 400;
}
small {
    font-size: smaller;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
dl {
    margin: 0 20px;
}
dt {
    font-weight: bold;
}
dd {
    margin: 0 0 20px;
}
menu,
ol,
ul {
    margin: 16px 0;
    padding: 0 0 0 40px;
}
ul {
    list-style-type: square;
}
li {
    outline: inherit;
}
nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}
li > ul,
li > ol {
    margin: 0;
}
img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
form {
    margin: 0;
}
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    min-width: inherit;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
    white-space: normal;
}
button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    appearance: textfield;
    padding-right: 2px;
    width: 270px;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
table {
    border-bottom: 1px solid #ededed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    line-height: 2;
    margin: 0 0 20px;
    width: 100%;
}
caption,
th,
td {
    font-weight: normal;
    text-align: left;
}
caption {
    font-size: 16px;
    margin: 20px 0;
}
th {
    font-weight: bold;
    text-transform: uppercase;
}
td {
    border-top: 1px solid #ededed;
    padding: 6px 10px 6px 0;
}
del {
    color: #333;
}
ins {
    background: #fff9c0;
    text-decoration: none;
}
hr {
    background-size: 4px 4px;
    border: 0;
    height: 1px;
    margin: 0 0 24px;
}
input,
button,
select,
textarea {
    border: inherit;
    outline: inherit;
    background: transparent;
    transition: all 0.4s ease-out 0s;
    &:focus,
    &:active {
        outline: none;
    }
}
input,
select,
textarea {
    width: 100%;
    font-size: 14px;
}
input,
select {
    height: 40px;
    padding: 0 15px;
}
.button:not(:disabled):not(.disabled) {
    cursor: pointer;
}
img {
    max-width: 100%;
    height: auto;
}
.skin-5 .button,
.skin-5 .el-tabs .el-tabs-links li.active {
    color: #fff !important;
}
